import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { LazyLoadEvent, PrimeNGConfig, SelectItem } from 'primeng/api';
import { EnumMenu, IColumnsExport, IContentExport, IInformation, IResponseMenu, IRow } from 'src/app/models/shared/table';
import { SpinService } from 'src/app/services/shared/spin.service';
import { TableService } from 'src/app/services/shared/table.service';

@Component({
  selector: 'app-table-lazy-load-check',
  templateUrl: './table-lazy-load-check.component.html',
  styleUrls: ['./table-lazy-load-check.component.css']
})
export class TableLazyLoadCheckComponent implements OnInit {
  @Output() actionResponseMenu = new EventEmitter<IResponseMenu>();
  @Output() actionLazyLoad = new EventEmitter<LazyLoadEvent>(true);
  @Output() exportContent = new EventEmitter<IContentExport>();
  @Output() editId = new EventEmitter<number>();
  @Output() emitirIdRow = new EventEmitter<number>();
  @Output() matricularAll = new EventEmitter<boolean>();
  @Output() matricularSelect = new EventEmitter<{ success: boolean, data: any[] }>();
  @Input() activeSelectAll: boolean;
  @Input() columnsSearch: string[] = [];
  @Input() columnsFilter: string[] = [];
  @Input() esVistaCDG: boolean;
  @Input() disponibleCarga: boolean = true;
  @Input() loading: boolean;
  @Input() information: IInformation =
  {
    title: '',
    typeMenu: EnumMenu.NONE,
    columns: [],
    content: [],
    service: {},
    acciones: [],
    totalRecords: 0,
    loading: false,
  };
  rowSpan: number;

  //Datos para controlar la selección de checkbox
  selectedRows: any[];

  activeSelect: boolean = false;

  filterTable: string;
  //Datos a exportar
  columnsExport: IColumnsExport[] = [];
  selectedColumnsExport: IColumnsExport[] = [];
  columnsToExport: any[] = [];
  expandedRows: { [key: number]: boolean } = {};

  matchModeOptions: SelectItem[];

  constructor(private tableService: TableService, private spinService: SpinService, private cdr: ChangeDetectorRef,private primengConfig: PrimeNGConfig) { }

  ngOnChanges(changes: SimpleChanges): void { }

  ngOnInit(): void {
    this.rowSpan = this.information.columns.length + 1;
    this.columnsExport = this.tableService.columnasExportar(this.information);

    const customFilterName = 'custom-equals';
    this.primengConfig.setTranslation({
      noFilter: 'Sin filtro'
    });
    this.matchModeOptions = [
      // { label: 'Comienza con', value: FilterMatchMode.STARTS_WITH },
      // { label: 'Contiene', value: FilterMatchMode.CONTAINS },{ label: 'No contiene', value: FilterMatchMode.NOT_CONTAINS },
      // { label: 'Termina con', value: FilterMatchMode.ENDS_WITH},
      { label: 'Igual', value: customFilterName },
    ];

    this.tableService.exitoMatricular.subscribe((success: boolean) => {
      if (success) {
        this.selectedRows = [];
        this.onSelectionChange(this.selectedRows);
      }
    });
  }

  /* Metodo para detectar cada vez que hay un cambio en la tabla emitir los nuevos valores*/
  loadData(event: LazyLoadEvent): void {
    this.filterTable = "";
    this.actionLazyLoad.emit(event);
    if (event.globalFilter != null) {
      this.filterTable = event.globalFilter;
    }
  }

  toggleRow(index: number) {
    this.expandedRows[index] = !this.expandedRows[index];
    this.cdr.markForCheck();

  }
  //Metodo para detectar las nuevas columnas ha exportar
  changeExportColumns(event: { value: string[] }) {
    this.selectedColumnsExport = event.value.map(value => {
      const itemsSelecionados = this.columnsExport.find(item => item.key === value);
      return {
        key: value,
        title: itemsSelecionados ? itemsSelecionados.title : '',
        isExport: true
      }
    });
    // console.log('columnas export',this.selectedColumnsExport);
  }

  emitirId(id: number) {
    this.editId.emit(id);
  }

  emitirIdData(id: number) {
    this.emitirIdRow.emit(id);
  }

  responderPadre(event: IResponseMenu) {
    this.actionResponseMenu.emit({
      type: event.type,
      realizar: event.realizar,
    });
  }
  trackByFn(index: number, item: any): number {
    return index; // O usa item.vin si vin es único
  }
  onRowExpand(event: any) {
    this.expandedRows = {};
    this.expandedRows[event.data.id] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.id];
  }
  expandAll() {
    this.information.content.forEach((item, index) => {
      this.expandedRows[index] = true;
    });

  }

  collapseAll() {
    this.expandedRows = {};
  }
  preparar() {
    this.columnsToExport = [];
    if (this.selectedColumnsExport != null && this.selectedColumnsExport.length > 0) {
      this.columnsToExport = this.selectedColumnsExport.map(i => ({ title: i.title, dataKey: i.key }));
    } else {
      this.columnsToExport = this.columnsExport.filter(i => i.isExport).map(i => ({ title: i.title, dataKey: i.key }));
    }
  }

  descargarPDF() {
    this.preparar();
    this.exportContent.emit(
      {
        columnsExport: this.selectedColumnsExport != null && this.selectedColumnsExport.length > 0 ? this.selectedColumnsExport.map(i => i.key).toString() : this.columnsExport.filter(i => i.isExport).map(i => i.key).toString(),
        columnsArray: this.columnsToExport,
        filter: this.filterTable,
        isPDF: true
      }
    );
  }

  descargarExcel() {
    this.preparar();
    this.exportContent.emit(
      {
        columnsExport: this.selectedColumnsExport != null && this.selectedColumnsExport.length > 0 ? this.selectedColumnsExport.map(i => i.key).toString() : this.columnsExport.filter(i => i.isExport).map(i => i.key).toString(),
        columnsArray: this.columnsToExport,
        filter: this.filterTable,
        isPDF: false
      }
    );
  }

  //Metodos para controlar la sellecion multiple
  onSelectionChange(value: any[] = []) {
    this.selectedRows = value;
    var arrayItems = value.map(item => item.id);
    if(arrayItems.length > 0 && this.activeSelectAll){
      this.activeSelect = true
    }else {
      this.activeSelect = false;
    }
  }

  matricularTodos(){
    this.matricularAll.emit(true);
  }

  matricularSeleccionados(){
    var arrayItems = this.selectedRows.map(item => item.id);
    this.matricularSelect.emit({ success: true, data: arrayItems });

  }
}
