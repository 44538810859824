import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';

export const FullLayout_ROUTES: Routes = [
    {
        path: '',
        redirectTo: '/',
        pathMatch: 'full'
    },
    {
        path: 'boleta',
        loadChildren: () => import('../../public/pages/boleta-calificaciones/boleta.module').then(m => m.BoletaModule),
    },
    // {
    //     path: 'authentication',
    //     loadChildren: () => import('../../core/pages/authentication/authentication.module').then(m => m.AuthenticationModule),
    //     canActivate: [AuthGuard],
    //     canMatch: [AuthGuard]
    // }
];
