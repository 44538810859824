<div class="header">
    <div class="logo logo-dark">
        <a href="">
            <img src="assets/images/logo/logo.png" alt="Logo">
            <img class="logo-fold" src="assets/images/logo/logo-fold.png" alt="Logo">
        </a>
    </div>
    <div class="logo logo-white">
        <a href="">
            <img src="assets/images/logo/COBAY HOR.png" class="w-55" alt="Logo">
            <img class="logo-fold w-85" src="assets/images/logo/COBAY VERT.png" alt="Logo">
        </a>
    </div>
    <div class="nav-wrap">
        <ul class="nav-left">
            <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
        </ul>
        <ul class="nav-right">
          <li>
            <p style="font-size: 13px;">
              <strong>Estas trabajando con: </strong>
              {{ plantel }}
              <br>
              <a *ngIf="superRol" (click)="quickViewToggle()">Cambiar</a>
            </p>
          </li>

            <li>
                <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
                    <nz-avatar [nzIcon]="'user'" nzSrc="assets/images/avatars/thumb-3.jpg"></nz-avatar>
                </span>
                <nz-dropdown-menu #profile="nzDropdownMenu">
                    <ul nz-menu class="p-b-15 p-t-20">
                        <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                            <div class="d-flex m-r-50">
                                <nz-avatar nzSize="large" nzSrc="assets/images/avatars/thumb-3.jpg"></nz-avatar>
                                <div class="m-l-10">
                                    <p class="m-b-0 text-dark font-weight-semibold">{{this.user}}</p>
                                    <p class="m-b-0 opacity-07">UI/UX Desinger</p>
                                </div>
                            </div>
                        </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="showUserDrawer()">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                    <span class="m-l-10">Editar perfil</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="showPassDrawer()">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                    <span class="m-l-10">Cambiar contraseña</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                                    <span class="m-l-10">Cerrar sesión</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                    </ul>
                </nz-dropdown-menu >
            </li>
            <li *ngIf= "superRol">
                <a (click)="quickViewToggle()">
                    <i nz-icon nzType="setting" theme="outline"></i>
                </a>
                <nz-drawer [nzWidth]="480" nzTitle="Planteles" [nzClosable]="false" [nzVisible]="quickViewVisible" nzPlacement="right" (nzOnClose)="quickViewToggle()">
                    <ng-container *nzDrawerContent>
                        <app-quick-view (nomPlantel)="nomPlantel($event)"></app-quick-view>
                    </ng-container>
                </nz-drawer>
            </li>
        </ul>
    </div>
</div>


<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzVisible]="isPassDrawerVisible"
  nzTitle={{passDrawerTitle}}
  [nzWidth]="720"
  (nzOnClose)="closePassDrawer()">

  <form nz-form [formGroup]="passForm" *nzDrawerContent>
    <nz-form-item>
        <nz-form-label [nzSm]="6">Nombre usuario</nz-form-label>
        <label [nzSm]="14">{{user}}</label>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSm]="6" nzRequired nzFor="currentPassword" >Contraseña anterior</nz-form-label>
        <nz-form-control [nzSm]="14" nzErrorTip="La contraseña debe tener al menos 8 caracteres, una mayúscula, una miníscula, un número y un caracter especial.">
        <input type="password" nz-input formControlName="currentPassword" placeholder="Contraseña anterior">
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSm]="6" nzRequired nzFor="newPassword" >Contraseña nueva</nz-form-label>
        <nz-form-control [nzSm]="14" nzErrorTip="La contraseña debe tener al menos 8 caracteres, una mayúscula, una miníscula, un número y un caracter especial.">
        <input type="password" nz-input formControlName="newPassword" placeholder="Contraseña nueva">
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSm]="6" nzRequired nzFor="confirmPassword" >Confirmar contraseña</nz-form-label>
        <nz-form-control [nzSm]="14" nzErrorTip="La contraseña debe tener al menos 8 caracteres, una mayúscula, una miníscula, un número y un caracter especial.">
        <input type="password" nz-input formControlName="confirmPassword" placeholder="Confirmar contraseña nueva">
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzSpan]="14" [nzOffset]="6">
          <button nz-button style="margin-right: 8px;" (click)="closePassDrawer()">Cancelar</button>
          <button nz-button nzType="primary" type="submit" (click)="submitPassForm()">Guardar</button>
      </nz-form-control>
    </nz-form-item>

  </form>
</nz-drawer>

<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzVisible]="isUserDrawerVisible"
  nzTitle={{userDrawerTitle}}
  [nzWidth]="720"
  (nzOnClose)="closeUserDrawer()">

  <form nz-form [formGroup]="userForm" *nzDrawerContent>
    <nz-form-item>
        <nz-form-label [nzSm]="6" nzRequired nzFor="name" >Nombre</nz-form-label>
        <nz-form-control [nzSm]="14" nzErrorTip="Este campo es requerido.">
            <input type="text" nz-input formControlName="name" placeholder="Introduzca su nombre." />
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSm]="6" nzRequired nzFor="email">Email</nz-form-label>
        <nz-form-control [nzSm]="14" nzErrorTip ="Debe ingresar una dirección de correo válida.">
            <input type="text" nz-input formControlName="email" placeholder="Introduzca su email.">
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzSpan]="14" [nzOffset]="6">
          <button nz-button style="margin-right: 8px;" (click)="closeUserDrawer()">Cancelar</button>
          <button nz-button nzType="primary" type="submit" (click)="submitUserForm()">Guardar</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-drawer>
