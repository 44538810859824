import { Direccion } from "../direcciones/direccion.interface";

export interface PlantelResponse {
    planteles       :IPlanteles[],
    totalDatos      :number;
}

export interface Plantel {
    id              :number,
    nombre          :string;
    descripcion     :string;
    cct             :string;
    idDireccion?    :number;
    idSubsistema?   :number;
    direccion       :Direccion;
    creadoPor       :string,
    modificadoPor   :string
}

export interface IPlanteles{
    id              :number,
    nombre          :string,
    descripcion     :string,
    cct             :string,
    idDireccion?    :number;
    idSubsistema?   :number;
    creadoPor       :string,
    modificadoPor   :string
}

export interface buscarAspirantes {
    idPlantel                :number| null,
    idPeriodo                :number,
    estatusDocumentacion     :number | null,
    estatusMatriculacion     :number | null,
    estatusPago              :number | null
}
