import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ThemeConstantService } from './services/theme-constant.service';
import { SearchPipe } from './pipes/search.pipe';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { TableLazyLoadCheckComponent } from './components/table-lazy-load-check/table-lazy-load-check.component';
import { TableLazyLoadComponent } from './components/table-lazy-load/table-lazy-load.component';
import { TableSubmenuComponent } from './components/table-submenu/table-submenu.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { TableExpandableComponent } from './components/table-expandable/table-expandable.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NuevoIngresoDocumentacionComponent } from '../views/procesos/nuevo-ingreso/nuevo-ingreso-documentacion/list/nuevo-ingreso-documentacion.component';
import { UploadComponent } from './components/upload/upload.component';
import { SimpleTableComponent } from './components/simple-table/simple-table.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { NzTagCustomComponent } from './components/nz-tag-custom/nz-tag-custom.component';
import { ChatCommentComponent } from './components/chat-comment/chat-comment.component';
import { SimpleTableCheckComponent } from './components/simple-table-check/simple-table-check.component';

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NzIconModule,
        PerfectScrollbarModule,
        SearchPipe,
        TableLazyLoadCheckComponent,
        SimpleTableCheckComponent,
        TableLazyLoadComponent,
        TableExpandableComponent,
        SimpleTableComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NzButtonModule,
        NzUploadModule,
        NzIconModule,
        NzToolTipModule,
        PerfectScrollbarModule,
        TableModule,
        ButtonModule,
        TooltipModule,
        MultiSelectModule ,
        InputTextModule,
        ConfirmDialogModule,
        ToastModule,
        FormsModule,
        NzTableModule,
        NzSwitchModule,
        PdfViewerModule,
        NzTagModule,
        NzAvatarModule,
        NzBadgeModule
    ],
    declarations: [
        SearchPipe,
        TableLazyLoadCheckComponent,
        SimpleTableCheckComponent,
        TableLazyLoadComponent,
        TableSubmenuComponent,
        TableExpandableComponent,
        NuevoIngresoDocumentacionComponent,
        UploadComponent,

        SimpleTableComponent,
        PdfViewerComponent,
        NzTagCustomComponent,
        ChatCommentComponent
    ],
    providers: [
        ThemeConstantService,
        ConfirmationService,
        MessageService
    ]
})

export class SharedModule { }
