import { Injectable } from '@angular/core';
import { NzNotificationDataOptions, NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationsngzService {

  constructor(private ns: NzNotificationService) { }

  optNotification: NzNotificationDataOptions = {
    nzDuration: 6000,
    nzAnimate: true,
    nzPauseOnHover: true,
  }

  error(message: string, titulo: string = null){

    this.optNotification.nzClass = ["bg-danger"];
    this.ns
      .error(
        titulo ? titulo : 'Error',
        message, 
        this.optNotification
      )
      .onClick.subscribe(() => {
      });
  }

  success(message: string, titulo: string = null){

    this.optNotification.nzClass = ["bg-success"];
    this.ns
      .success(
        titulo ? titulo : 'Éxito',
        message,
        this.optNotification
      )
      .onClick.subscribe(() => {
      });
  }

  info(message: string, titulo: string = null){

    this.optNotification.nzClass = ["bg-info"];
    this.ns
      .info(
        titulo ? titulo : '¡Atención!',
        message, 
        this.optNotification
      )
      .onClick.subscribe(() => {
      });
  }

  warning(message: string, titulo: string = null){

    this.optNotification.nzClass = ["bg-warning"];
    this.ns
      .warning( 
        titulo ? titulo : '¡Advertencia!',
        message, 
        this.optNotification
      )
      .onClick.subscribe(() => {
      });
  }
}
