import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NZ_I18N, es_ES } from 'ng-zorro-antd/i18n';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';

import { registerLocaleData, PathLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { TemplateModule } from './shared/template/template.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';

import { NgChartjsModule } from 'ng-chartjs';
import { ThemeConstantService } from './shared/services/theme-constant.service';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AuthState } from './store/auth/states/auth.state';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ServerErrorsInterceptor } from './core/interceptors/serverError.interceptor';
import { NzNotificationModule } from 'ng-zorro-antd/notification';



//reCaptchaV3
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import es from '@angular/common/locales/es';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { AuthenticationService } from './services/authentication/authentication.service';

registerLocaleData(es);

function initializeRecaptcha(authService: AuthenticationService): () => Promise<void> {
    return () => authService.loadRecaptchaKeys();
}
  
@NgModule({
    declarations: [
        AppComponent,
        CommonLayoutComponent,
        FullLayoutComponent,
    ],
    imports: [
      NgxsModule.forRoot([AuthState]),
      NgxsReduxDevtoolsPluginModule.forRoot(),
      NgxsStoragePluginModule.forRoot(),
      BrowserModule,
      BrowserAnimationsModule,
      TableModule,
      FormsModule,
      AppRoutingModule,
      NzBreadCrumbModule,
      TemplateModule,
      SharedModule,
      NgChartjsModule,
      RecaptchaV3Module,
      NzNotificationModule,
      NzInputNumberModule,
      NzSpinModule,
      NzAlertModule,
      NzModalModule,
      HttpClientModule,
      ReactiveFormsModule,
    ],
    providers: [
        {
            provide: NZ_I18N,
            useValue: es_ES,
        },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ServerErrorsInterceptor,
          multi:true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeRecaptcha,
            deps: [AuthenticationService],
            multi: true
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useFactory: (authService: AuthenticationService) => authService.configuracion?.llavePublica || '',
            deps: [AuthenticationService]
            // useValue: environment.recaptcha.siteKey,
        },
        ThemeConstantService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
