<div class="container-fluid mt-3">
  <div class="chat chat-app row">
    <div class="chat-content w-100 h-80" [ngClass]="{'open': isContentOpen}">
      <div class="conversation">
        <div class="conversation-wrapper">
          <div class="conversation-header justify-content-between">
            <div class="media align-items-center">
              <a [routerLink]="[]" class="m-r-20 d-md-none d-block text-dark font-size-18 m-t-5">
                <i nz-icon nzType="left-circle" theme="outline"></i>
              </a>
              <div>
                <nz-avatar class="shadow" nzIcon="user"
                  [nzSrc]="'assets/images/avatars/group-profile-default.png'"></nz-avatar>
              </div>
              <div class="p-l-15">
                <h6 class="m-b-0">Grupo de Revisión de documentos</h6>
                <p class="m-b-0 text-muted font-size-13">
                  <nz-badge nzStatus="success"></nz-badge>
                  <span>Online</span>
                </p>
              </div>
            </div>
          </div>
          <div class="conversation-body" #scrollBottom>
            <ng-container *ngIf="chatList && chatList.length > 0; else noMessages">
              <ng-container>
                <div *ngFor="let item of chatList">
                  <div class="msg" [ngClass]="'justify-content-center'">
                    <div class="font-weight-semibold font-size-12">
                      <span class="text-center">{{item.fecha | date:'d MMM y, h:mm a'}}</span>
                    </div>
                  </div>
                  <div class="msg" [ngClass]="{'msg-recipient':item.from == 'opposite', 'msg-sent': item.from == 'me'}">
                    <div class="m-r-10">
                      <nz-avatar [nzIcon]="'user'" [nzSrc]="'assets/images/avatars/avatar-default.png'"></nz-avatar>
                    </div>
                    <div class="bubble">
                      <div class="bubble-wrapper">
                        <span>{{item.comentario}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="msg-sender small text-muted" [ngClass]="{'text-left':item.from == 'opposite', 'text-right': item.from == 'me'}">
                    enviado por: {{ item.usuario }}
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noMessages>
              <div class="no-messages">
                <p class="text-center">Aún no hay mensajes, escribe para iniciar la conversación</p>
              </div>
            </ng-template>
          </div>
          <div class="conversation-footer position-static" *ngIf="!disabled">
            <input [(ngModel)]="msg" class="chat-input" type="text" placeholder="Escribe un mensaje..."
              (keydown.enter)="sendMsg(msg);$event.preventDefault()">
            <ul class="list-inline d-flex align-items-center m-b-0">
              <!-- <li class="list-inline-item">
                <a class="text-gray font-size-20" nz-tooltip nzTooltipTitle="Emoji">
                  <i nz-icon nzType="smile" theme="outline"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a class="text-gray font-size-20" nz-tooltip nzTooltipTitle="Attachment">
                  <i nz-icon nzType="paper-clip" theme="outline"></i>
                </a>
              </li> -->
              <li class="list-inline-item">
                <button class="d-none d-md-block" nz-button nzType="primary" (click)="sendMsg(msg)">
                  <span class="m-r-10">Enviar</span>
                  <i class="far fa-paper-plane"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
