import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AuthStateModel } from "src/app/models/states/auth.state.interface";
import { ClearAuthData, SetAuthData, UpdatePlantelAuthData } from "../actions/auth.actions";
import { Token } from "@angular/compiler";

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    idUser: null,
    jwtToken: null,
    refreshToken: null,
    email: null,
    nombre: null,
    rol: null,
    idPlantel: null
  }
})

@Injectable()
export class AuthState {
  //Selectors
  @Selector()
  static authData(state: AuthStateModel) {
    return state;
  }

  @Selector()
  static isLoggedIn(state: AuthStateModel): boolean{
    return !!state.jwtToken;
  }

  //Actions
  @Action(SetAuthData)
  setAuthData({ setState }: StateContext<AuthStateModel>, { payload }: SetAuthData) {
    setState(payload);
  }

  @Action(ClearAuthData)
  clearAuthData({ setState }: StateContext<AuthStateModel>) {
    setState({
      idUser: null,
      jwtToken: null,
      refreshToken: null,
      email: null,
      nombre: null,
      rol: null,
      idPlantel: null
    });
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('idPlantel');
  }

  @Action(UpdatePlantelAuthData)
  updatePlantelAuthData({ setState }: StateContext<AuthStateModel>, { payload }: SetAuthData) {
    setState(payload);
  }
}
