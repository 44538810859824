import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinService {

  isSpinn: boolean;
  constructor() { }

  isSpin(): boolean{
    return this.isSpinn;
  }

  showSpin(): void{
    this.isSpinn = true;
  }

  closeSpin(): void{
    this.isSpinn = false;
  }
}
