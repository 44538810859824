import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseHelper } from 'src/app/core/helpers/responseHelper.interface';
import { ChangePassword, Usuario } from 'src/app/models/usuarios/usuario.interface';
import { IContentExport } from 'src/app/models/shared/table';
import { environment } from 'src/environments/environment';
import { ResponseHelperModel } from 'src/app/models/helpers/response-helper.interface';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  private baseUrl: string = environment.apiUrl;
  private controller: string = 'api/Account';

  constructor(private http:HttpClient) { }

  create(form:Usuario): Observable<ResponseHelper<Usuario>> {
    return this.http.post<ResponseHelperModel<Usuario>>(`${this.baseUrl}${this.controller}/register`, form);
  }

  update(form: Usuario):Observable<ResponseHelperModel<Usuario>>{
    return this.http.put<ResponseHelperModel<Usuario>>(`${this.baseUrl}${this.controller}/UpdateAccount`, form);
  }

  updateUser(form: Usuario):Observable<ResponseHelperModel<Usuario>>{
    return this.http.put<ResponseHelperModel<Usuario>>(`${this.baseUrl}${this.controller}/UpdateUserData`, form);
  }

  changePassword(form: ChangePassword):Observable<ResponseHelperModel<ChangePassword>>{
    return this.http.put<ResponseHelperModel<ChangePassword>>(`${this.baseUrl}${this.controller}/ChangePassword`, form);
  }

  delete(id:number): Observable<ResponseHelperModel<Usuario>> {
    return this.http.delete<ResponseHelperModel<Usuario>>(`${this.baseUrl}${this.controller}/DeleteUser/${id}`);
  }
  
  getById(id:string): Observable<ResponseHelperModel<Usuario>> {
    return this.http.get<ResponseHelperModel<Usuario>>(`${this.baseUrl}${this.controller}/GetUserById/${id}`);
  }

  getByEmail(email: string): Observable<ResponseHelperModel<Usuario>>{
    return this.http.get<ResponseHelperModel<Usuario>>(`${this.baseUrl}${this.controller}/GetUserByEmail/${email}`);
  }

  getAll(): Observable<ResponseHelperModel<Usuario[]>>{
    return this.http.get<ResponseHelperModel<Usuario[]>>(`${this.baseUrl}${this.controller}/GetUsers`);
  }

  export(exportContent: IContentExport): Observable<any[]>{
    let content = new HttpParams()
    .set('columnsExport',exportContent.columnsExport)
    .set('filter', exportContent.filter)
    return this.http.get<any[]>
    (`${this.baseUrl}${this.controller}/ExportContent?${content}`);
  }

}
