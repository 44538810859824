<nz-upload
  [nzFileList]="fileList"
  [nzBeforeUpload]="beforeUpload"
  nzListType="picture"
  [nzDisabled]="disabled"
  [nzPreview]="handlePreview"
  (nzRemove)="removeFile()"
  (nzChange)="onFileChange($event)"
  [nzAccept]="acceptExtensions"
>
  <button nz-button>
    <i nz-icon nzType="upload"></i>
    Seleccionar File
  </button>
</nz-upload>
<div *ngFor="let file of fileList">
  <a target="_blank" (click)="downloadFile(file,true)">
    Descargar
  </a>
</div>
