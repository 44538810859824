<div class="m-b-30" *ngIf="existPlanteles else noExist">
    <div class="m-t-5 m-b-10" *ngIf = "changePlantel">
        <nz-alert nzType="success" nzMessage="Se cambio el plantel" nzShowIcon></nz-alert>
    </div> 

    <h5 class="m-b-0">¡Aviso Importante!</h5>
    <p style="text-align: justify;">Al cambiar la selección en esta lista, estás modificando de manera global el uso del plantel en todo el sistema. 
        Esta acción afectará todas las configuraciones y funcionalidades relacionadas con el plantel seleccionado. 
        Por favor, asegúrate de seleccionar el plantel con el que desea trabajar.</p>
    <div class="m-t-10">
        <form nz-form [formGroup] ="plantelForm">
            <nz-form-item>
                <nz-form-control>
                    <nz-select nzShowSearch formControlName="idPlantel"  [nzPlaceHolder]="'Selecciona un plantel'" style="width: 420px; font-size: 13px;">
                        <nz-option *ngFor="let option of plantelesList;" [nzLabel]="option.nombre" [nzValue]="option.id"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
 
        <nz-divider></nz-divider>
        <button nz-button nzType="primary" (click)="cambiarPlantel()" [disabled] = "!btnHabilitar">Cambiar</button>
    </div>
</div>

<ng-template #noExist>
    <nz-alert nzType="info" nzMessage="No hay planteles disponibles" nzShowIcon></nz-alert>
</ng-template>