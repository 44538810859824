import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseHelper } from 'src/app/core/helpers/responseHelper.interface';
import { IPlanteles, Plantel, PlantelResponse } from 'src/app/models/planteles/plantel.interface';
import { IContentExport, ITableLazyLoad } from 'src/app/models/shared/table';
import { environment } from 'src/environments/environment';
import { ResponseHelperArray, ResponseHelperModel } from 'src/app/models/helpers/response-helper.interface';
import { map } from 'rxjs/operators';
import { Secundaria } from 'src/app/models/procesos/secundaria.interface';
import { IFirma } from 'src/app/models/planteles/firmas.interface';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/states/auth.state';
import { AuthStateModel } from 'src/app/models/states/auth.state.interface';
import { Roles } from 'src/app/models/authentication/roles.interface';

@Injectable({
  providedIn: 'root'
})
export class PlantelesService {
  private baseUrl: string = environment.apiUrl;
  private controller: string = 'api/Planteles';

  private plantelIdGlobal = new BehaviorSubject<number>(0);
  idPlantel = this.plantelIdGlobal.asObservable();

  constructor(private http:HttpClient, private store: Store) {}

  setPlantelId(id: number) {
    this.plantelIdGlobal.next(id);
  }

  create(form:Plantel):Observable<ResponseHelperModel<Plantel>>{
    return this.http.post<ResponseHelperModel<Plantel>>(`${this.baseUrl}${this.controller}`, form);
  }

  update(form: Plantel):Observable<ResponseHelperModel<Plantel>>{
    return this.http.put<ResponseHelperModel<Plantel>>(`${this.baseUrl}${this.controller}/${form.id}`, form);
  }
  delete(id:number): Observable<ResponseHelperModel<Plantel>> {
    return this.http.delete<ResponseHelperModel<Plantel>>(`${this.baseUrl}${this.controller}/${id}`);
  }

  getAll(): Observable<ResponseHelperArray<Plantel>> {
    return this.http.get<ResponseHelperArray<Plantel>>(`${this.baseUrl}${this.controller}`);
  }

  getById(id:number): Observable<ResponseHelperModel<Plantel>> {
    return this.http.get<ResponseHelperModel<Plantel>>(`${this.baseUrl}${this.controller}/${id}`);
  }

  getByNameSecundaria(nombre: string): Observable<ResponseHelperModel<Secundaria >> {
    return this.http.get<ResponseHelperModel<Secundaria >>(`${this.baseUrl}api/Secundaria/GetByName/${nombre}`);
  }

  getByIdSecundaria(idSecundaria: number): Observable<ResponseHelperModel<Secundaria >> {
    return this.http.get<ResponseHelperModel<Secundaria >>(`${this.baseUrl}api/Secundaria/${idSecundaria}`);
  }

  getFirmantes(): Observable<ResponseHelperModel<IFirma>> {
    const datos = new HttpParams()
    .set('idPlantel', this.plantelIdGlobal.value.toString())

    return this.http.get<ResponseHelperModel<IFirma>>(`${this.baseUrl}${this.controller}/GetFirmantes`, { params: datos });
  }

  saveFirmantes(form: IFirma): Observable<ResponseHelperModel<IFirma>> {
    const formData = new FormData();

    for (const key in form) {
      if (form.hasOwnProperty(key)) {
        const value = form[key];

        if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      }
    }

    return this.http.post<ResponseHelperModel<IFirma>>(`${this.baseUrl}${this.controller}/SaveFirmantes`, formData);
  }


  list(table: ITableLazyLoad): Observable<PlantelResponse[]> {
    //if filters are null, load all data
    if (table == null) {
      table =
      {
        page: 0,
        pageSize: 1000,
        sortField: '',
        sortOrder: 0,
        filter: ''
      }
    }

    let datos = new HttpParams()
      .set('page', table.page)
      .set('pageSize',table.pageSize)
      .set('sortField', table.sortField)
      .set('sortOrder', table.sortOrder)
      .set('filter', table.filter);
    return this.http.get<PlantelResponse[]>(
      `${this.baseUrl}${this.controller}/list?${datos}`
    );
  }

  export(exportContent: IContentExport): Observable<ResponseHelper<Plantel[]>>{
    let content = new HttpParams()
    .set('columnsExport',exportContent.columnsExport)
    .set('filter', exportContent.filter)
    return this.http.get<ResponseHelper<Plantel[]>>
    (`${this.baseUrl}${this.controller}/export?${content}`);
  }

  planteleslist(): Observable<any[]>{
    return this.http.get<ResponseHelperArray<Plantel>>(`${this.baseUrl}${this.controller}/shor`).pipe(
      map(response => {
        let listPlantel: any[] = [];
        if (response.data.length > 0) {
          response.data.forEach(item => {
            listPlantel.push({ id: item.id, nombre: item.nombre });
          });
        }
        return listPlantel;
      })
    );
  }

  isAdmin(): Observable<boolean> {
    return this.store.select(AuthState.authData).pipe(
      map((authState: AuthStateModel) => 
        authState.rol === Roles.ADMIN || authState.rol === Roles.SA || authState.rol === Roles.CEDG
      )
    );
  }
}
