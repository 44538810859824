import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComentarioDocumentoTramite } from 'src/app/models/operacion/documentos/documentoTramite.interface';

@Component({
  selector: 'app-chat-comment',
  templateUrl: './chat-comment.component.html',
  styleUrls: ['./chat-comment.component.css']
})
export class ChatCommentComponent implements OnInit {
  msg: string;
  @Input() chatList: ComentarioDocumentoTramite[] = [];
  @Input() disabled: boolean = false;
  @Output() comentario = new EventEmitter<ComentarioDocumentoTramite>();
  isContentOpen: boolean = true;
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.cd.markForCheck();
    this.cd.detectChanges();
  }
  sendMsg(msg: string) {
    if (msg.trim().length === 0) {
      return; // No agregar mensajes vacíos
    }
    const nuevoComentario: ComentarioDocumentoTramite = {
      comentario: msg,
      fecha: new Date(),
      usuario: 'me',
      from:'me',
      avatar : ''
    };
    this.chatList = [...this.chatList, nuevoComentario];
    this.comentario.emit(nuevoComentario);
    this.cd.markForCheck();
    this.msg = '';
}
}
