import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FilterMatchMode, FilterService, LazyLoadEvent, PrimeNGConfig, SelectItem } from 'primeng/api';
import {
  EnumMenu,
  IColumnsExport,
  IContentExport,
  IInformation,
  IInformationSimple,
  IResponseMenu,
} from 'src/app/models/shared/table';
import { SpinService } from 'src/app/services/shared/spin.service';
import { TableService } from 'src/app/services/shared/table.service';

@Component({
  selector: 'app-simple-table-check',
  templateUrl: './simple-table-check.component.html',
  styleUrls: ['./simple-table-check.component.css'],
})
export class SimpleTableCheckComponent implements OnInit {
  @Output() actionResponseMenu = new EventEmitter<IResponseMenu>();
  @Output() actionLazyLoad = new EventEmitter<LazyLoadEvent>(true);
  @Output() exportContent = new EventEmitter<IContentExport>();
  @Output() editId = new EventEmitter<number>();
  @Output() itemsSeleccionados = new EventEmitter<number[]>();
  @Input() activeSelectAll: boolean;
  @Input() columnsSearch: string[] = [];
  @Input() columnsFilter: string[] = [];
  @Input() esVistaCDG: boolean;

  @Output() emitirIdRow = new EventEmitter<number>();
  @Input() information: IInformationSimple =
    {
      title: '',
      typeMenu: EnumMenu.NONE,
      columns: [],
      content: [],
      service: {},
      acciones: [],
    };

  rowSpan: number;
  selectedRows: any[] = [];
  activeSelect: boolean = false;
  textButtonSelectAll: string = 'Seleccionar todas las clases';

  filterTable: string;
  //Datos a exportar
  columnsExport: IColumnsExport[] = [];
  selectedColumnsExport: IColumnsExport[] = [];
  columnsToExport: any[] = [];

  matchModeOptions: SelectItem[];
  constructor(private tableService: TableService, private spinService: SpinService, private filterService: FilterService,private primengConfig: PrimeNGConfig) { }


  ngOnInit(): void {
    this.rowSpan = this.information.columns.length + 1;
    this.columnsExport = this.tableService.columnasExportar(this.information);
    this.columnsSearch = this.information.columns.map(col => col.key);
    const customFilterName = 'custom-equals';
    this.primengConfig.setTranslation({
      noFilter: 'Sin filtro'
    });
    this.filterService.register(customFilterName, (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return value.toString() === filter.toString();
    });
    this.matchModeOptions = [
      { label: 'Comienza con', value: FilterMatchMode.STARTS_WITH },
      { label: 'Contiene', value: FilterMatchMode.CONTAINS },{ label: 'No contiene', value: FilterMatchMode.NOT_CONTAINS },
      { label: 'Termina con', value: FilterMatchMode.ENDS_WITH},{ label: 'Igual', value: customFilterName },
    ];
  }

  //Metodo para detectar las nuevas columnas ha exportar
  changeExportColumns(event: { value: string[] }) {
    this.selectedColumnsExport = event.value.map(value => {
      const itemsSelecionados = this.columnsExport.find(item => item.key === value);
      return {
        key: value,
        title: itemsSelecionados ? itemsSelecionados.title : '',
        isExport: true
      }
    });
  }

  emitirId(id: number) {
    this.editId.emit(id);
  }

  emitirIdData(id: number) {
    this.emitirIdRow.emit(id);
  }
  responderPadre(event: IResponseMenu) {
    this.actionResponseMenu.emit({
      type: event.type,
      realizar: event.realizar,
    });
  }


  preparar() {
    this.columnsToExport = [];
    if (this.selectedColumnsExport != null && this.selectedColumnsExport.length > 0) {
      this.columnsToExport = this.selectedColumnsExport.map(i => ({ title: i.title, dataKey: i.key }));
    } else {
      this.columnsToExport = this.columnsExport.filter(i => i.isExport).map(i => ({ title: i.title, dataKey: i.key }));
    }
  }

  descargarPDF() {
    this.preparar();
    this.exportContent.emit(
      {
        columnsExport: this.selectedColumnsExport != null && this.selectedColumnsExport.length > 0 ? this.selectedColumnsExport.map(i => i.key).toString() : this.columnsExport.filter(i => i.isExport).map(i => i.key).toString(),
        columnsArray: this.columnsToExport,
        filter: this.filterTable,
        isPDF: true
      }
    );
  }

  descargarExcel() {
    this.preparar();
    this.exportContent.emit(
      {
        columnsExport: this.selectedColumnsExport != null && this.selectedColumnsExport.length > 0 ? this.selectedColumnsExport.map(i => i.key).toString() : this.columnsExport.filter(i => i.isExport).map(i => i.key).toString(),
        columnsArray: this.columnsToExport,
        filter: this.filterTable,
        isPDF: false
      }
    );
  }


  onSelectionChange(value: any[] = []) {
    this.selectedRows = value;
    var arrayItems = value.map((item) => item.id);
    if (arrayItems.length > 0) {
      this.activeSelect = true;
    } else {
      this.activeSelect = false;
    }
  }

  activarTodosLosCheck() {
    if (
      this.selectedRows.length === 0 ||
      this.information.content.length > this.selectedRows.length
    ) {
      this.selectedRows = this.information.content.map((item) => item);
      this.activeSelect = this.selectedRows.length > 0;
      this.textButtonSelectAll = 'Deseleccionar todas las clases';
    } else {
      this.selectedRows = [];
      this.activeSelect = false;
      this.textButtonSelectAll = 'Seleccionar todas las clases';
    }
  }

  generarExcelesCalificaciones() {
    let arrayItems = this.selectedRows.map((item) => item.id);
    this.itemsSeleccionados.emit(arrayItems);
  }
}
