import { EstatusDocumentacionService } from './../../../services/shared/estatus-documentacion.service';
import { Component, Input, OnInit } from '@angular/core';
import { es } from 'date-fns/locale';
import { EstatusDocumentacionAspirante, EstatusDocumentacionAspiranteCEDG } from 'src/app/core/enums/enums';
import { EstatusInicial } from 'src/app/models/operacion/documentos/documentoTramite.interface';
@Component({
  selector: 'app-nz-tag-custom',
  templateUrl: './nz-tag-custom.component.html',
  styleUrls: ['./nz-tag-custom.component.css']
})
export class NzTagCustomComponent implements OnInit {
  spanClass: string;
  text: string;
  @Input() id: string;
  @Input() estatus: string;
  userRol: string;
  userRolsViewAdmin: string[] = ['Admin', 'CEDG'];
  constructor(private estatusDocumentacionService: EstatusDocumentacionService) { }

  ngOnInit(): void {
    this.userRol = localStorage.getItem('role') || '';
    this.estatusDocumentacionService.getEstatusObservable(this.id).subscribe(estatus => {
      this.spanClass = this.getClaseParaEstatus(estatus);
      this.text = this.getEstatus(estatus);
    });

  }

  getClaseParaEstatus(estatusAsignar: EstatusInicial): string {

    var estatusNum = estatusAsignar.estatusCEP != '' || estatusAsignar.estatusCEP != null ? Number(estatusAsignar.estatusCEP) : 0;
    var estatus = estatusNum as EstatusDocumentacionAspirante;

    if(estatus == EstatusDocumentacionAspirante.APROBADO_CEP){
      return 'badge-success';
    }else{
      switch (estatus) {
        case EstatusDocumentacionAspirante.SIN_CARGA:
          return 'badge-red';
        case EstatusDocumentacionAspirante.CARGA_PARCIAL:
          return 'badge-info';
        case EstatusDocumentacionAspirante.OBSERVACIONES:
          return 'badge-warning';
        default:
          return '';
      }
    }

  }

  getEstatus(estatusAsignar: EstatusInicial): string {
    // console.log(estatusAsignar);
    var estatusNum = estatusAsignar.estatusCEP != '' || estatusAsignar.estatusCEP != null ? Number(estatusAsignar.estatusCEP) : 0;
    var estatus = estatusNum as EstatusDocumentacionAspirante;

    var estatusNum2 = estatusAsignar.estatusCEDG != '' || estatusAsignar.estatusCEDG != null ? Number(estatusAsignar.estatusCEDG) : 0;
    var estatus2 = estatusNum2 as EstatusDocumentacionAspiranteCEDG;

    if(estatus == EstatusDocumentacionAspirante.APROBADO_CEP){
      if(estatus2 == EstatusDocumentacionAspiranteCEDG.SIN_ASIGNAR || estatus2 == EstatusDocumentacionAspiranteCEDG.OBSERVACIONES){
        return EstatusDocumentacionAspirante[estatus];
      }else{
        return EstatusDocumentacionAspiranteCEDG[estatus2];
      }
    }else{
      return EstatusDocumentacionAspirante[estatus];
    }

  }

}
