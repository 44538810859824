import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DocumentoTramitesService } from '../../../../../services/procesos/documento-tramites.service';
import { ComentarioDocumentoTramite, DocumentoTramiteInterface, GetDocumentoTramiteVM } from 'src/app/models/operacion/documentos/documentoTramite.interface';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { EstatusDocumentacionAspirante, EstatusDocumentoTramite } from 'src/app/core/enums/enums';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EstatusDocumentacionService } from 'src/app/services/shared/estatus-documentacion.service';
import { ChatCommentComponent } from 'src/app/shared/components/chat-comment/chat-comment.component';
import { AuthStateModel } from 'src/app/models/states/auth.state.interface';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/states/auth.state';
import { PdfViewerComponent } from '../../../../../shared/components/pdf-viewer/pdf-viewer.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';


@Component({
  selector: 'app-nuevo-ingreso-documentacion',
  templateUrl: './nuevo-ingreso-documentacion.component.html',
  styleUrls: ['./nuevo-ingreso-documentacion.component.css']
})
export class NuevoIngresoDocumentacionComponent implements OnInit {
  data: GetDocumentoTramiteVM[] = [];
  comentarios: ComentarioDocumentoTramite[] = [];
  loaders: boolean[] = [];
  inputBloqueado: boolean[] = [];
  auth: AuthStateModel;
  @Input() idAspirante: number;
  @Input() idTramite: number;
  @Input() esVistaCDG: boolean;
  @Input() disponibleCarga: boolean = false;
  @Input() inputsBloqueados: boolean = false;

  loading = false;
  constructor(private documentoTramitesService: DocumentoTramitesService, private store: Store, private modal: NzModalService,
    private estatusDocumentacionService: EstatusDocumentacionService, private cdr: ChangeDetectorRef, private notification: NzNotificationService) {

  }

  ngOnInit(): void {
    this.loadData();
    this.store.select(AuthState.authData).subscribe((authState: AuthStateModel) => {
      this.auth = authState;
    });
    this.inputsBloqueados = !this.disponibleCarga;
  }

  clickSwitch(index: number): void {
    let documentoTramite = this.data[index].documentoTramite;
    if (documentoTramite.comentarios.length > 0 || (documentoTramite.documentoFile != undefined || (documentoTramite.ubicacion != '' && documentoTramite.ubicacion != null))) {
      this.loaders[index] = !this.loaders[index];
      this.data[index].documentoTramite.aceptado = !this.data[index].documentoTramite.aceptado;
      if (this.data[index].documentoTramite.aceptado == true) {
        this.data[index].documentoTramite.estatus = this.esVistaCDG ? EstatusDocumentoTramite.APROBADOCEDG : EstatusDocumentoTramite.APROBADOCEP;
        if (!this.esVistaCDG) {
          this.inputBloqueado[index] = true;
        } else {
          this.inputBloqueado[index] = false;
        }
      } else {
        this.data[index].documentoTramite.estatus = this.esVistaCDG ? EstatusDocumentoTramite.RECHAZADOCEDG : EstatusDocumentoTramite.RECHAZADOCEP;
      }
    } else {
      this.data[index].documentoTramite.estatus = this.esVistaCDG ? EstatusDocumentoTramite.RECHAZADOCEDG : EstatusDocumentoTramite.RECHAZADOCEP;
      this.data[index].documentoTramite.aceptado = false;
    }
    this.guardarDatos(null, index);

  }

  onFileSelected(file: NzUploadFile, index: number): void {
    if (file != null) {
      this.data[index].documentoTramite.estatus = EstatusDocumentoTramite.CARGADO;
      this.guardarDatos(file, index);
    }
  }

  private guardarDatos(file: NzUploadFile | null, index: number) {
    this.inputsBloqueados = true;
    const { comentariosCEDG, comentariosCEP, ubicacion } = this.data[index].documentoTramite;
    this.data[index].documentoTramite.comentariosCEP = comentariosCEP === null ? '' : comentariosCEP;
    this.data[index].documentoTramite.comentariosCEDG = comentariosCEDG === null ? '' : comentariosCEDG;
    this.data[index].documentoTramite.ubicacion = ubicacion === null ? '' : ubicacion;
    if (file) {
      this.data[index].documentoTramite.documentoFile = file.originFileObj;
    }

    const documentoTramiteModel = this.data[index].documentoTramite as DocumentoTramiteInterface;
    documentoTramiteModel.idTramiteAlumno = this.idTramite;
    documentoTramiteModel.creadoPor = this.auth?.email ?? "me";;
    documentoTramiteModel.fechaEliminacion = documentoTramiteModel.fechaCreacion;
    documentoTramiteModel.fechaModificacion = documentoTramiteModel.fechaCreacion;
    documentoTramiteModel.IsViewCDG = this.esVistaCDG;

    return this.documentoTramitesService.addOrUpdate(documentoTramiteModel).subscribe(
      response => {
        if (response.success) {
          this.data[index].documentoTramite.id = response.data?.id;
          this.data[index].documentoTramite.ubicacion = response.data?.ubicacion;
          let estatusInicial = { estatusCEP: response.data?.estatusDocumentacionCEPAspirante.toString(), estatusCEDG: response.data?.estatusDocumentacionCEDGAspirante.toString() };
          this.estatusDocumentacionService.changeEstatus(response.data?.idAspirante.toString(), estatusInicial);
        } else {
          this.notification.error(
            '¡Error!',
            response.message,
            {
              nzStyle: { background: 'red', color: 'white' }
            }
          );
        }
        this.loaders[index] = false;
        this.inputsBloqueados = false;
      },
      error => {
        this.loaders[index] = false;
        this.inputsBloqueados = false;
        // Manejo de errores aquí
        console.error('Error al agregar o actualizar el documento:', error);
        // Puedes agregar lógica adicional aquí, como mostrar un mensaje de error al usuario
      }
    );
  }


  openModalChatComentario(index: number) {
    const modalRef = this.modal.create({
      nzTitle: null,
      nzContent: ChatCommentComponent,
      nzWidth: '50%',
      nzComponentParams: {
        chatList: this.data[index].documentoTramite.comentarios ?? [],
        disabled: this.inputBloqueado[index] || this.inputsBloqueados
      },
      nzFooter: null,
      nzStyle: {
        top: '20px'
      }
    });

    const instance = modalRef.getContentComponent() as ChatCommentComponent;
    if (instance) {
      instance.comentario.subscribe((comentario: ComentarioDocumentoTramite) => {
        this.onMensajeEnviado(index, comentario);
      });
    }
    this.cdr.markForCheck();
  }

  onMensajeEnviado(index: number, comentario: ComentarioDocumentoTramite) {
    comentario.usuario = this.auth?.nombre ?? "me";
    const documentoTramite = this.data[index].documentoTramite;
    this.data[index].documentoTramite.comentarios = this.data[index].documentoTramite.comentarios ?? [];
    this.data[index].documentoTramite.comentarios.push(comentario);
    this.data[index].documentoTramite.comentariosCEDG = JSON.stringify(this.data[index].documentoTramite.comentarios);
    if (documentoTramite.comentarios.length > 0) {
      this.data[index].documentoTramite.estatus = EstatusDocumentoTramite.OBSERVACIONES;
      this.data[index].documentoTramite.aceptado = false;
    } else {
      this.data[index].documentoTramite.estatus = this.esVistaCDG ? EstatusDocumentoTramite.RECHAZADOCEDG : EstatusDocumentoTramite.RECHAZADOCEP;
      this.data[index].documentoTramite.aceptado = false;
    }
    this.guardarDatos(null, index);
  }




  private loadData(): void {
    this.documentoTramitesService.listaDocumentoAspirante(this.idTramite).subscribe(response => {
      if (response && response.data) {
        this.data = response.data;
        let index = 0;
        this.data.map(item => {
          item.documentoTramite.aceptado = ((item.documentoTramite.estatus == EstatusDocumentoTramite.APROBADOCEDG && this.esVistaCDG) || ((item.documentoTramite.estatus == EstatusDocumentoTramite.APROBADOCEP || item.documentoTramite.estatus == EstatusDocumentoTramite.APROBADOCEDG) && !this.esVistaCDG));
          this.inputBloqueado.push(item.documentoTramite.aceptado);
          const tempArray: any[] = JSON.parse(item.documentoTramite.comentariosCEDG) ?? [];
          item.documentoTramite.comentarios = tempArray.map(item => ({
            ...item,
            fecha: new Date(item.fecha),
            from: item.usuario == this.auth?.nombre ? 'me' : 'opposite'
          }));

        });
        this.loaders = Array.from({ length: this.data.length }, () => false);
      }
      this.loading = false;
      this.cdr.detectChanges();
    });
  }


  //ShowPreviewModal
  handlePreview = (file: string): void => {
    if (file) {
      if (this.isImage(file)) {
        this.showImagePreview(file);
      } else if (this.isPDF(file)) {
        this.showPdfPreview(file);
      } else {
        this.notification.error(
          '¡Error!',
          'Archivo no compatible para vista previa.',
          {
            nzStyle: { background: 'red', color: 'white' }
          }
        );
        console.warn('Archivo no compatible para vista previa.');
      }
    } else {
      this.notification.error(
        '¡Error!',
        'Archivo no disponible para vista previa en este momento.',
        {
          nzStyle: { background: 'red', color: 'white' }
        }
      );
      console.warn('Archivo no disponible para vista previa en este momento.');
    }

  }

  async downloadFile(url: string, filename: string = 'archivo'): Promise<void> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        this.notification.error(
          '¡Error!',
          'Archivo no disponible.',
          {
            nzStyle: { background: 'red', color: 'white' }
          }
        );
      }
      const blob = await response.blob();
      const link = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      link.href = objectUrl;
      link.download = filename;
      document.body.appendChild(link); // Necesario para Firefox
      link.click();
      URL.revokeObjectURL(objectUrl); // Limpia la URL del objeto
      document.body.removeChild(link); // Limpia después de usarlo
    } catch (error) {
      this.notification.error(
        '¡Error!',
        'Error al descargar el archivo.',
        {
          nzStyle: { background: 'red', color: 'white' }
        }
      );
      console.error('Error al descargar el archivo:', error);
    }
  }


  isImage(file: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']; // Puedes agregar más extensiones si es necesario
    const extension = file.split('.').pop()?.toLowerCase();
    return extension ? imageExtensions.includes(extension) : false;
  }

  isPDF(file: string): boolean {
    const pdfExtensions = ['pdf'];
    const extension = file.split('.').pop()?.toLowerCase();
    return extension ? pdfExtensions.includes(extension) : false;
  }

  showImagePreview(file: string): void {
    this.modal.create({
      nzTitle: 'Vista previa de la imagen',
      nzContent: `<img src="${file}" alt="image" class="imgpreview">`,
      nzWidth: '70%',
      nzFooter: null
    });
  }

  showPdfPreview(file: string): void {
    if (file != null) {
      this.modal.create({
        nzTitle: 'Vista previa del PDF',
        nzContent: PdfViewerComponent,
        nzComponentParams: {
          showModal: true,
          pdfDataUrl: file
        },
        nzFooter: null,
        nzWidth: '70%',
        nzStyle: {
          top: '20px',
        }
      });
    }
  }

}
