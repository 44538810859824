import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EstatusInicial } from 'src/app/models/operacion/documentos/documentoTramite.interface';

@Injectable({
  providedIn: 'root'
})
export class EstatusDocumentacionService {
  private estatusSubjects: { [key: string]: BehaviorSubject<EstatusInicial> } = {};

  getEstatusObservable(id: string): Observable<EstatusInicial> {
    if (!this.estatusSubjects[id]) {
      this.estatusSubjects[id] = new BehaviorSubject<EstatusInicial>(null);
    }
    return this.estatusSubjects[id].asObservable();
  }

  setInitialEstatus(id: string, estatus: EstatusInicial) {
    if (!this.estatusSubjects[id]) {
      this.estatusSubjects[id] = new BehaviorSubject<EstatusInicial>(estatus);
    } else {
      this.estatusSubjects[id].next(estatus);
    }
  }

  changeEstatus(id: string, estatus: EstatusInicial) {
    if (this.estatusSubjects[id]) {
      this.estatusSubjects[id].next(estatus);
    } else {
      this.setInitialEstatus(id, estatus);
    }
  }
}
