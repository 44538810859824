import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { ResponseHelper } from "src/app/models/helpers/response-helper.interface";
import { AuthStateModel } from "src/app/models/states/auth.state.interface";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { HandleErrorService } from "src/app/services/handleErrors/handleErrors.service";
import { NotificationsngzService } from "src/app/services/shared/notificationsngz.service";
import { SpinService } from "src/app/services/shared/spin.service";
import { ClearAuthData, SetAuthData } from "src/app/store/auth/actions/auth.actions";
import { AuthState } from "src/app/store/auth/states/auth.state";

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(
    private error: HandleErrorService,
    private store: Store,
    private ns: NotificationsngzService,
    private as: AuthenticationService,
    private router: Router,
    private ss: SpinService
    ){}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.ss.showSpin();
    let token = null;
    this.store.select(AuthState.authData).subscribe((authState: AuthStateModel) => {
        token = authState.jwtToken;
    });
    if(token){
      req = this.addTokenHeader(req, token);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if(error.status == 401){
          return this.handle401Error(req, next);
        }
        this.error.handleError(error);
        this.ss.closeSpin();
        return throwError(() => error);
      }),
    );
  }

  private handle401Error(request:HttpRequest<any>, next: HttpHandler ){

    let usuario: AuthStateModel = null;
    this.store.select(AuthState.authData).subscribe((authState: AuthStateModel) => {
        usuario = authState;
    });
    let formData = new FormData();
    formData.append('Email', usuario.email);
    formData.append("Id", usuario.idUser);
    formData.append('AccessToken', usuario.jwtToken);
    formData.append('RefreshToken', usuario.refreshToken);

    return this.as.refresh(formData).pipe(
      catchError((error: HttpErrorResponse) => {
        this.store.dispatch(new ClearAuthData());
        this.ns.error("La sesión ha caducado, es necesario iniciar sesión de nuevo");
        this.router.navigate(['./']);
        this.ss.closeSpin();
        return throwError(() => error);
      }),
      switchMap((response: ResponseHelper) => {
        let authData : AuthStateModel = {
          email: response.data.email,
          idUser: response.data.id,
          nombre: response.data.nombre,
          jwtToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
          rol: response.data.rol,
          idPlantel: response.data.idPlantel
        };
        this.store.dispatch(new ClearAuthData());
        this.store.dispatch(new SetAuthData(authData));
        this.ss.closeSpin();
        return next.handle(this.addTokenHeader(request,authData.jwtToken));
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ setHeaders: {
      Authorization: `Bearer ${token}`
    } });
  }
}
