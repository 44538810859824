<br />
<div class="row justify-content-center align-items-center">
    <div class="col-lg-12">
        <p-toast></p-toast>
        <p-table #dt dataKey="id" [columns]="information.columns" [value]="information.content" [lazy]="true"
            [paginator]="true" [rows]="10" [totalRecords]="information.totalRecords" (onLazyLoad)="loadData($event)"
            [rowsPerPageOptions]="[10, 25, 50]" [tableStyle]="{'min-width': '30rem',
        'font-family': 'apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
        'font-size':'14px'}">
            <ng-template pTemplate="caption">
                <div class="row justify-content-end align-items-end text-left">
                    <div class="col-lg-12 justify-content-center">
                        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="descargarExcel()"
                            class="p-button-success mr-2" [style]=" {'background-color': '#22c55e'}" pTooltip="EXCEL"
                            tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="descargarPDF()"
                            class="p-button-danger mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                        <p-multiSelect class="mSelect" [options]="columnsExport" defaultLabel="Columnas a descargar"
                            optionLabel="title" optionValue="key" name="export" (onChange)="changeExportColumns($event)"
                            [maxSelectedLabels]="3" [selectedItemsLabel]="'{0} columnas seleccionadas'">
                        </p-multiSelect>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let columns>
                <tr class="header-class">
                    <ng-container *ngFor="let column of information.columns">
                        <th *ngIf="column.watch" [class]="column.class" [pSortableColumn]="column.key">
                            {{ column.title }} <p-sortIcon [field]="column.key"></p-sortIcon>
                        </th>
                    </ng-container>
                    <th class="text-center" style="width: 20%;">
                        Acciones
                    </th>
                </tr>
                <tr>
                    <ng-container *ngFor="let column of information.columns">
                        <th *ngIf="column.watch" [class]="column.class">
                            <p-columnFilter type="text" [field]="column.key" [matchModeOptions]="matchModeOptions"
                                [matchMode]="'custom-equals'"></p-columnFilter>
                        </th>
                    </ng-container>
                    <th></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-row let-rowIndex="rowIndex" let-columns="columns">
                <tr class="body-class">
                    <ng-container *ngFor="let column of columns">
                        <td *ngIf="column.watch" [class]="column.class">
                            <span class="p-column-title font-weight-bold"> {{column.title}} </span>
                            <div *ngIf="row.tag != null && column.tag != null && column.tag; else campoNormal">
                                <p-tag [style]="row.tag.style" [severity]="row.tag.severity" [rounded]="row.tag.rounded"
                                    [icon]="row.tag.icon" [value]="row.content[column.key]"></p-tag>
                            </div>
                            <ng-template #campoNormal>
                                {{row.content[column.key]}}
                            </ng-template>
                        </td>
                    </ng-container>

                    <td *ngIf="information.typeMenu !== 0">
                        <app-table-submenu *ngIf="row.tag.severity == 'success'" [typeMenu]="information.typeMenu"
                            [service]="information.service" [row]="row.content"
                            (actionResponseMenu)="responderPadre($event)" [controller]="information.controller"
                            (editId)="emitirId($event)" [acciones]="information.acciones"
                            (emitirIdRow)="emitirIdData($event)"
                            (emitirIndexButton)="emitirIndexButtonData($event)"
                            ></app-table-submenu>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [colSpan]="rowSpan" style="text-align: center;">
                        <i class="pi pi-exclamation-circle" style="font-size: 2rem; color: #cecbcbd3;"></i>
                        <br />Sin registros
                    </td>
                </tr>
            </ng-template>

        </p-table>
        <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
    </div>

</div>
