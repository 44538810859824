import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";

import { FullLayout_ROUTES } from "./shared/routes/full-layout.routes";
import { CommonLayout_ROUTES } from "./shared/routes/common-layout.routes";
import { AuthGuard } from './core/guards/auth.guard';
import { LoginGuard } from './core/guards/login.guard';
import { Roles } from './models/authentication/roles.interface';


const appRoutes: Routes = [
    {
        path: 'public',
        component: FullLayoutComponent,
        children: FullLayout_ROUTES,
    },
    {
        path: 'authentication',
        loadChildren: () => import('./core/pages/authentication/authentication.module').then(m => m.AuthenticationModule),
    },
    {
        path: '',
        component: CommonLayoutComponent,
        children: CommonLayout_ROUTES,
        canMatch: [AuthGuard],
        data: { roles: [Roles.ADMIN, Roles.USER, Roles.CEP, Roles.CEDG, Roles.SA] }
    },
    {
        path: '**',
        redirectTo: '/authentication/error',
        pathMatch: 'full'

    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}
