import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanMatch, Route, Router, RouterStateSnapshot, UrlSegment } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { AuthState } from "src/app/store/auth/states/auth.state";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanMatch, CanActivate {
    
    constructor(
        private store : Store,
        private router : Router,
        private authService: AuthenticationService
    ){}

    canMatch(route: Route, segments: UrlSegment[]): boolean  | Observable<boolean> | Promise<boolean> {
        const navigateRoles = route.data?.roles;
        const userRol = this.authService.getUserRole();
        if(!this.store.selectSnapshot(AuthState.isLoggedIn) || !navigateRoles.includes(userRol)){
            this.router.navigate(['./authentication']);
            return false;
        }
        return true;
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        const navigateRoles = route.data?.roles;
        const userRol = this.authService.getUserRole();
        if(!this.store.selectSnapshot(AuthState.isLoggedIn) || !navigateRoles.includes(userRol)){
            this.router.navigate(['./authentication']);
            return false;
        }
        return true;
    }
}