import { Component } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { SideNavInterface } from '../../interfaces/side-nav.type';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html'
})

export class SideNavComponent{

    public menuItems: any[]
    isFolded : boolean;
    isSideNavDark : boolean;
    isExpand : boolean;

    constructor( private themeService: ThemeConstantService, private authService: AuthenticationService) {}

    ngOnInit(): void {

        const userRole = this.authService.getUserRole();

        this.menuItems = this.filterUserRolMenu(ROUTES, userRole);  
        // this.menuItems = ROUTES.filter(menuItem => !menuItem.roles || menuItem.roles.includes(userRole));
        // this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        // this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    }

    closeMobileMenu(): void {
        if (window.innerWidth < 992) {
            this.isFolded = false;
            this.isExpand = !this.isExpand;
            this.themeService.toggleExpand(this.isExpand);
            this.themeService.toggleFold(this.isFolded);
        }
    }

    filterUserRolMenu(menuItems: SideNavInterface[], userType: string): SideNavInterface[] {
        return menuItems
          .filter(menuItem => !menuItem.roles || menuItem.roles.includes(userType))
          .map(subMenu => {
            if (subMenu.submenu && subMenu.submenu.length > 0) {
              return {
                ...subMenu,
                submenu: subMenu.submenu
                  .filter(subMenuItem => !subMenuItem.roles || subMenuItem.roles.includes(userType))
                  .map(subItem => ({
                    ...subItem,
                    submenu: subItem.submenu ? this. filterUserRolMenu(subItem.submenu, userType) : []
                  }))
              };
            } else {
              return subMenu;
            }
        });
    }
}
