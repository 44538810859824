import { Component } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { Store } from '@ngxs/store';
import { AuthStateModel } from 'src/app/models/states/auth.state.interface';
import { AuthState } from 'src/app/store/auth/states/auth.state';
import { ClearAuthData } from 'src/app/store/auth/actions/auth.actions';
import { Router } from '@angular/router';
import { Roles } from 'src/app/models/authentication/roles.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { NotificationsngzService } from 'src/app/services/shared/notificationsngz.service';
import { EstatusUsuario } from 'src/app/core/enums/enums';
import { PlantelesService } from 'src/app/services/planteles/planteles.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent {

    searchVisible: boolean = false;
    quickViewVisible: boolean = false;
    isFolded: boolean;
    isExpand: boolean;
    user: string;
    userId: string;
    plantelId: number;
    superRol: boolean;
    plantel: string;

    //Edit profile drawer
    isUserDrawerVisible: boolean = false;
    userDrawerTitle: string = "Editar perfil";
    userForm: FormGroup = this.fb.group({
        id: [null],
        name: [null, [Validators.required, Validators.maxLength(60)]],
        email: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"), Validators.maxLength(60)]],
        password: [null],
        confirmPassword: [null],
        idPlantel: [null],
        rol: [null],
        estatusUsuario: [null]
    });

    //Change password drawer
    isPassDrawerVisible: boolean = false;
    passDrawerTitle: string = "Cambiar contraseña";
    passForm: FormGroup = this.fb.group({
        id: [null],
        currentPassword: [null, [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$"), Validators.maxLength(60)]],
        newPassword: [null, [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$"), Validators.maxLength(60)]],
        confirmPassword: [null, [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$"), Validators.maxLength(60)]]
    });


    constructor(private themeService: ThemeConstantService, private store: Store, private router: Router,
        private fb: FormBuilder, private usuariosService: UsuariosService, private ns: NotificationsngzService,
        private plantelesService: PlantelesService,private authService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.store.select(AuthState.authData).subscribe((authState: AuthStateModel) => {
            this.userId = authState.idUser;
            this.user = authState.nombre;

            this.superRol = [Roles.ADMIN, Roles.SA, Roles.CEDG].includes(authState.rol);
            this.plantelId = authState.idPlantel;

                if (this.plantelId !== null && this.plantelId !== 0) {
                    this.plantelesService.setPlantelId(this.plantelId);
                    this.plantelesService.getById(this.plantelId).subscribe(response => {
                        if (response.success) {
                            this.nomPlantel(response.data.nombre);
                        }
                    });
                }

        });
    }

    logout(): void {
        this.store.dispatch(new ClearAuthData());
        this.authService.saveIdPlantel(null);
        this.router.navigate(['./']);
    }

    toggleFold() {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
    }

    toggleExpand() {
        this.isFolded = false;
        this.isExpand = !this.isExpand;
        this.themeService.toggleExpand(this.isExpand);
        this.themeService.toggleFold(this.isFolded);
    }

    searchToggle(): void {
        this.searchVisible = !this.searchVisible;
    }

    quickViewToggle(): void {
        this.quickViewVisible = !this.quickViewVisible;
    }

    nomPlantel(name: string) {
        this.plantel = name;
    }

    //Edit profile drawer
    closeUserDrawer(): void {
        this.isUserDrawerVisible = false;
    }

    showUserDrawer(): void {
        try {
            this.usuariosService.getById(this.userId).subscribe(response => {
                this.userForm.patchValue(response.data);
            });
            this.isUserDrawerVisible = true;
        } catch (error) {
            this.isUserDrawerVisible = false;
            console.error('Error en la llamada a getById: ' + this.userId, error);
        }
    }

    submitUserForm() {
        for (const i in this.userForm.controls) {
            this.userForm.controls[i].markAsDirty();
            this.userForm.controls[i].updateValueAndValidity();
        }

        if (this.userForm.valid) {

            this.userForm.value.id = this.userId;
            //this.userForm.value.idPlantel = this.plantelId;
            //this.userForm.value.estatusUsuario = 0;

            this.usuariosService.updateUser(this.userForm.value).subscribe(response => {
                //this.ss.closeSpin();
                if (response.success) {
                    this.ns.success(response.message);
                    this.closeUserDrawer();
                } else {
                    this.ns.error(response.message)
                }
            });
        }
    }

    //Change password drawer
    closePassDrawer(): void {
        this.isPassDrawerVisible = false;
    }

    showPassDrawer(): void {
        this.passForm.reset();
        this.isPassDrawerVisible = true;
    }

    submitPassForm() {
        for (const i in this.passForm.controls) {
            this.passForm.controls[i].markAsDirty();
            this.passForm.controls[i].updateValueAndValidity();
        }

        if (this.passForm.valid) {
            if (this.passForm.value.newPassword == this.passForm.value.confirmPassword) {
                this.passForm.value.id = this.userId;

                this.usuariosService.changePassword(this.passForm.value).subscribe(response => {
                    //this.ss.closeSpin();
                    if (response.success) {
                        this.ns.success(response.message);
                        this.closePassDrawer();
                    } else {
                        this.ns.error(response.message)
                    }
                });
            } else {
                this.ns.error("La confirmación no coincide.");
            }
        }
    }
}
