import { Roles } from 'src/app/models/authentication/roles.interface';
import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
    {
        path: 'anuncios',
        title: 'Inicio',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'home',
        submenu: [],
        roles: [Roles.USER, Roles.ADMIN, Roles.SA, Roles.CEDG, Roles.CEP]
    },
    // {
    //     path: 'dashboard/estudiantesPlantel',
    //     title: 'Estudiantes Plantel',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'home',
    //     submenu: [],
    //     roles: [Roles.USER, Roles.ADMIN, Roles.SA, Roles.CEDG, Roles.CEP]
    // },
    {
        path: '',
        title: 'Operación',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'audit',
        roles: [Roles.ADMIN, Roles.SA, Roles.CEDG, Roles.CEP],
        submenu: [
            {
                path: '/procesos/nuevo-ingreso',
                title: 'Nuevo Ingreso',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'usergroup-add',
                submenu: []
            },
            {
                path: '/aspirantes/list',
                title: 'Matriculación',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'usergroup-add',
                submenu: [],
                roles: [Roles.ADMIN, Roles.SA, Roles.CEDG]

            },
            {
                path: '/procesos/reinscripcion',
                title: 'Reinscripción',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'usergroup-add',
                submenu: []
            },
            {
                path: '/procesos/carga-de-calificaciones',
                title: 'Carga de calificaciones',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'schedule',
                submenu: []
            },
            {
                path: '/preparacion-semestre',
                title: 'Grupos',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'group',
                submenu: []
            },
            {
                path: 'cierre-semestre',
                title: 'Cierre de semestre',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'issues-close',
                submenu: []
            },
            {
                path: 'regularizaciones',
                title: 'Regularizaciones',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'redo',
                submenu: []
            },
            {
                path: 'bajas-temporales',
                title: 'Bajas temporales',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'down-circle',
                submenu: []
            },
            {
                path: 'equivalencias',
                title: 'Equivalencias',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'ant-design',
                submenu: []
            },
            {
                path: 'revalidaciones',
                title: 'Revalidaciones',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'rollback',
                submenu: []
            },
            {
                path: 'convalidaciones',
                title: 'Convalidaciones',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'control',
                submenu: []
            },
            {
                path: 'traslados',
                title: 'Traslados',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'reconciliation',
                submenu: []
            }
        ]
    },
    {
        path: '',
        title: 'Gestión',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'schedule',
        // roles: [Roles.ADMIN, Roles.SA, Roles.CEDG, Roles.CEP],
        submenu: [
            {
                path: 'alumnos/list',
                title: 'Alumnos',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'user',
                submenu: [],
                // roles: [Roles.ADMIN, Roles.SA, Roles.CEDG],
            },
            {
                path: 'importaciones/importacion-alumnos',
                title: 'Importar alumnos',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'team',
                submenu: [],
                roles: [Roles.ADMIN, Roles.SA, Roles.CEDG],
            },
            {
                path: 'ciclos/list',
                title: 'Ciclos y periodos',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'build',
                submenu: [],
                roles: [Roles.ADMIN, Roles.SA, Roles.CEDG],
            },
            {
                path: 'catalogos/profes/list',
                title: 'Profesores',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'user',
                submenu: [],
                roles: [Roles.ADMIN, Roles.SA, Roles.CEDG],
            },
            {
                path: 'catalogos/profesores/list',
                title: 'Profesores',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'user',
                submenu: [],
                roles: [Roles.CEP],
            },

            {
                path: 'planesEstudio',
                title: 'Planes de estudio',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'profile',
                submenu: [],
                roles: [Roles.ADMIN, Roles.SA, Roles.CEDG],
            },
            {
                path: 'gruposHorarios/tiposGrupo',
                title: 'Tipos de grupo',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'group',
                submenu: [],
                // roles: [Roles.ADMIN, Roles.SA, Roles.CEDG],
            },
            {
                path: 'usuarios/list',
                title: 'Usuarios',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'user',
                submenu: [],
                roles: [Roles.ADMIN, Roles.SA, Roles.CEDG],
            },
            {
                path: 'documentos/paquetes',
                title: 'Paquetes',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'container',
                submenu: [],
                roles: [Roles.ADMIN, Roles.SA, Roles.CEDG],
            },
            {
                path: 'documentos/documentos',
                title: 'Documentos',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'file',
                submenu: [],
                roles: [Roles.ADMIN, Roles.SA, Roles.CEDG],
            },
            {
                path: 'documentos/tramites',
                title: 'Tramites',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'file',
                submenu: [],
                roles: [Roles.ADMIN, Roles.SA, Roles.CEDG],
            },
            {
                path: '/procesos/firmas-plantel',
                title: 'Firma de Planteles',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'file-search',
                submenu: []
            },
            {
                path: 'equivalencias-planes',
                title: 'Equivalencia de planes',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'file-search',
                submenu: [],
                roles: [Roles.ADMIN, Roles.SA, Roles.CEDG],
            },
            {
                path: 'planteles',
                title: 'Planteles',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'team',
                submenu: [],
                roles: [Roles.ADMIN, Roles.SA, Roles.CEDG],
            }

        ]
    },
    {
        path: '',
        title: 'Reportes',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'file-text',
        submenu: [
          {
            path: 'estadisticas/list',
            title: 'Estadísticas Generales',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'line-chart',
            submenu: [],
          },
          {

            path: 'reportes/general',
            title: 'Reprobación',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'pie-chart',
            submenu: [],
            roles: [Roles.ADMIN, Roles.SA, Roles.CEP]
          },
          {
            path: '',
            title: 'Estadísticas INEGI',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'bar-chart',
            submenu: [],
          },
          {
            path: '',
            title: 'Concentrado calificaciones',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'dot-chart',
            submenu: [],
          },
          {
            path: '',
            title: 'Alumnos maya hablante',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'schedule',
            submenu: [],
          }
        ]
    },
    {
        path: '',
        title: 'Configuraciones',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'setting',
        submenu: [
          {
              path: 'settings/lists',
              title: 'Correo, recapcha, etc',
              iconType: 'nzIcon',
              iconTheme: 'outline',
              icon: 'mail',
              submenu: []
          },
          {
              path: 'roles/list',
              title: 'Roles',
              iconType: 'nzIcon',
              iconTheme: 'outline',
              icon: 'gold',
              submenu: []
          },
        ],
        roles: [Roles.ADMIN],
    }

]
