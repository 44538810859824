import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit{
  @Input() showModal: boolean = false;
  @Input() pdfDataUrl: string | null = null;
  @Input() isServer: boolean = false;
  safeUrl: SafeResourceUrl | null = null;

  constructor(private sanitizer: DomSanitizer){

  }
  ngOnInit() {
    if (this.pdfDataUrl) {
      this.safeUrl = this.pdfDataUrl;
     /*  if(!this.isServer){
        this.safeUrl = this.pdfDataUrl;
      }else{
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfDataUrl);
      } */
    }
  }
  closeModal() {
    // Aquí puedes agregar la lógica para cerrar el modal
  }

}
