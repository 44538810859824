import { AuthStateModel } from "src/app/models/states/auth.state.interface";

export class SetAuthData {
  static readonly type = '[Auth] Establecer datos de autenticación';
  constructor(public payload:AuthStateModel) {}
}

export class ClearAuthData {
  static readonly type = '[Auth] Borrar datos de autenticación';
}

export class UpdatePlantelAuthData {
  static readonly type = '[Auth] Modificar los datos de autenticación';
  constructor(public payload:AuthStateModel) {}
}