<div class="p-3">
  <!-- Encabezado general -->
  <div class="general-header">
    <h2>Documentación del aspirante</h2>
  </div>

  <div class="data-container">
    <div class="data-header">
      <!-- Encabezados alternativos para otro tipo de usuario -->
      <div class="data-header-item">Documento</div>
      <div class="data-header-item">Archivo</div>
      <div class="data-header-item">Estado</div>
      <div class="data-header-item">Comentario</div>
    </div>
    <ng-container *ngIf="loading; else dataContent">
      <div class="loader">
        <p>Cargando...</p>
      </div>
    </ng-container>
    <!-- Lista de datos -->
    <ng-template #dataContent>
      <ng-container *ngIf="data.length > 0; else noDataTemplate">
        <div *ngFor="let item of data; let i = index">
          <div class="data-row">
            <div class="data-cell">{{ item.documentoTramite.nombre }}</div>
            <div class="data-cell">
              <div class="m-l-15">
                <div class="clearfix" *ngIf="esVistaCDG;else subirDocumentacion">
                  <ng-container *ngIf="item.documentoTramite.ubicacion; else sinDocumento">
                    <button pButton pRipple type="button" icon="pi pi-file-edit" style="margin-right: .3em;"
                      pTooltip="Ver documento" tooltipPosition="top" (click)="handlePreview(item.documentoTramite.ubicacion)">
                    </button>
                    <div>
                      <a target="_blank" (click)="downloadFile(item.documentoTramite.ubicacion,item.documentoTramite.nombre)">
                        Descargar
                      </a>
                    </div>
                  </ng-container>
                  <ng-template #sinDocumento>
                    <span>Sin documento</span>
                  </ng-template>
                </div>
                <ng-template #subirDocumentacion>
                  <div class="clearfix">
                    <app-upload  (fileSelected)="onFileSelected($event, i)" [disabled]="inputsBloqueados || inputBloqueado[i]" [fileName]="item.documentoTramite.nombre + item.documentoTramite.extension" [fileUrl]="item.documentoTramite.ubicacion"></app-upload>
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="data-cell">
              <nz-switch [(ngModel)]="item.documentoTramite.aceptado" [disabled]="inputsBloqueados" [nzControl]="true" (click)="clickSwitch(i)"
                [nzLoading]="loaders[i]"></nz-switch>
            </div>
            <div class="data-cell">
              <span *ngIf="item.documentoTramite && item.documentoTramite.comentarios && item.documentoTramite.comentarios.length > 0">
                <span><strong>{{ item.documentoTramite.comentarios[item.documentoTramite.comentarios.length - 1].usuario }}:</strong></span><br/>{{ item.documentoTramite.comentarios[item.documentoTramite.comentarios.length - 1].comentario }}
              </span>
              <span *ngIf="!item.documentoTramite || !item.documentoTramite.comentarios || item.documentoTramite.comentarios.length === 0">
                Sin Comentarios
              </span>
              <br/>
              <a (click)="openModalChatComentario(i)">Hacer comentario</a>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #noDataTemplate>
      <div class="loader">
        <p>No hay datos disponibles</p>
      </div>
    </ng-template>
  </div>
</div>
