import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ResponseHelper } from 'src/app/core/helpers/responseHelper.interface';
import { IContentExport, ITableLazyLoad } from 'src/app/models/shared/table';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/auth/states/auth.state';
import { AuthStateModel } from 'src/app/models/states/auth.state.interface';
import { DocumentoTramiteInterface, GetDocumentoTramiteResponse } from 'src/app/models/operacion/documentos/documentoTramite.interface';

@Injectable({
  providedIn: 'root'
})

export class DocumentoTramitesService {
  private baseUrl: string = environment.apiUrl;
  private controller: string = 'api/DocumentoTramite';
  private userId: string = 'EmptyUser';

  constructor(private http:HttpClient, private store: Store) {
    this.store.select(AuthState.authData).subscribe((authState: AuthStateModel) => {this.userId = authState.idUser;});
  }

  addOrUpdate(form: DocumentoTramiteInterface): Observable<ResponseHelper<DocumentoTramiteInterface>> {
    const formData: FormData = new FormData();
    for (const key in form) {
      if (form.hasOwnProperty(key)) {
        if (key === 'documentoFile' && form[key] instanceof File) {
          formData.append(key, form[key], form[key].name);

        } else {
          formData.append(key, form[key] as any);
        }
      }
    }
    // Send the FormData object in the HTTP request
    return this.http.post<ResponseHelper<DocumentoTramiteInterface>>(`${this.baseUrl}${this.controller}`, formData);
  }

  listaDocumentoAspirante(idTramite:number): Observable<GetDocumentoTramiteResponse> {
    return this.http.get<GetDocumentoTramiteResponse>(`${this.baseUrl}${this.controller}/ListDocumentation/${idTramite}`);
  }

}
